var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default',[_c('div',{staticClass:"tw-mb-4 c-blue-text"},[_c('v-chip-group',{attrs:{"mandatory":"","active-class":" primary"}},[_c('v-chip',{attrs:{"active-class":"false","to":{ name: 'Dashboard' }}},[_vm._v("Dashboard ")]),_c('span',{staticClass:"mt-2 mx-2"},[_vm._v(" > ")]),_c('v-chip',{attrs:{"to":{ name: 'AgentGuide' }}},[_vm._v(" "+_vm._s(_vm.$options.name)+" ")])],1),_c('div',{staticClass:"tw-mt-20"},[_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('div',{staticClass:"tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('hr'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.proposals,"sort-by":"activePolEffectiveDate","search":_vm.search,"sort-desc":_vm.sortDesc,"page":_vm.pagination.pageNumber,"items-per-page":_vm.pagination.perPage,"loading":_vm.loadingTable,"footer-props":{
          itemsPerPageOptions: [10, 20, 30, 40, 50],
        }},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "perPage", $event)}},scopedSlots:_vm._u([{key:"item.activePolSA",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatNum")(item.activePolSA)))])]}},{key:"item.activePolPremium",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatNum")(item.activePolPremium)))])]}},{key:"item.activePolEffectiveDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatToHuman")(item.activePolEffectiveDate)))])]}}],null,true)})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }